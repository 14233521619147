import { dev } from '$app/environment';
import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

if (!dev) {
	// init({
	// 	dsn: 'https://5683f4470b0cc97bd8f4437c57eb975c@o4506469133385728.ingest.sentry.io/4506469133582336',
	// 	tracesSampleRate: 1.0,
	// 	// This sets the sample rate to be 10%. You may want this to be 100% while
	// 	// in development and sample at a lower rate in production
	// 	replaysSessionSampleRate: 0.1,
	// 	// If the entire session is not sampled, use the below sample rate to sample
	// 	// sessions when an error occurs.
	// 	replaysOnErrorSampleRate: 1.0,
	// 	// If you don't want to use Session Replay, just remove the line below:
	// 	intSentry.egrations: [],
	// });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
